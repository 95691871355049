import React, {useContext} from "react";
import { UserContext } from "../../../Context/UserContext";

export default function Upload2({ goNext }) {
    const {
        setPdf, setPdfBase64
    } = useContext(UserContext);
    //const [pdf, setPdf] = useState(null);
    

    const uploadDok = (event) =>{
        //console.log("upload click");
        if (event.target.files) {
            //console.log("event upload click");
            setPdf(event.target.files[0]);
            //const formInput = event.target.name;
            var files = event.currentTarget.files[0];
            const reader = new FileReader();
            reader.onload = function (fileLoadedEvent) {
                //console.log("event reader");
                var uploadedfile = fileLoadedEvent.target.result;
                var base64 = uploadedfile.replace(/^data:.+;base64,/, "");
                //console.log("base64pdf",base64);
                setPdfBase64(base64);
                //setInputAjb({ ...inputAjb, [formInput]: base64 });
            };
            reader.readAsDataURL(files);
          }
        goNext("step3")
    }
    return (
        <>
        <br />
        <div className="flex flex-col bg-white w-full mx-auto p-2">
            <h1 className="block text-center text-blue text-xl font-bold mb-2">Unggah Dokumen</h1>
            <div className="text-center text-sm mb-2">Unggah Dokumen anda untuk melakukan Pembubuhan</div>
            <div className="flex justify-center h-250-px w-full border-2 border-blue-400 bg-blueGray-200 pt-2 border-dashed rounded">
                <label className="flex flex-col text-center justify-center w-full h-full" htmlFor="upload-button">
                <i className="far fa-file-alt text-6xl py-2"></i>
                <p className="text-center text-sm">
                    Klik disini untuk unggah dokumen
                </p>
                </label>
                <input type="file" className="flex w-full h-full z-2"
                            id="upload-button"
                            style={{ display: "none" }}
                            name='dokumen'
                            onChange={uploadDok}
                            // required={true}
                            />
            </div>
            
            
            
            
        </div>
        </>
    )
}
