import React, {useState,useContext,useEffect} from "react";

import { UserContext } from "../../../Context/UserContext";
import cookies from "js-cookie";
import swal from "sweetalert";




export default function Form1({ goNext }) {
    //const [formDocSingleData, setformDocSingleData] = useState({nomor_dok: "",nama_dok: "",tipe_dok: "dokumen_lain"});
     // eslint-disable-next-line
    const [isLoading, setIsLoading] = useState(false);
    const {functions,formDocSingleData, setFormDocSingleData} = useContext(UserContext);
    const { refreshToken, cekQuota } = functions;
    const authDataJson = localStorage.getItem("authentication");
    const auth = JSON.parse(authDataJson); 

    useEffect(()=>{
      cekQuota("emeterai");
      // eslint-disable-next-line
    },[])
    //const parenFunction = () =>goNext("step2");

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormDocSingleData((prevformDocSingleData) => ({ ...prevformDocSingleData, [name]: value }));
      };

    const handleSubmit = (event) => {
        event.preventDefault();
        //alert(`no: ${formDocSingleData.nomor_dok}, name: ${formDocSingleData.nama_dok}, tipe: ${formDocSingleData.tipe_dok}`);
        //createDocument(formDocSingleData);
        goNext("step2");
    }

    // const createDocument = async (formDocSingleData) => {
    //   //setRincianIsLoading(true);
    //   await fetch(
    //     process.env.REACT_APP_BACKEND_HOST_TRANSACTION +
    //       "/api/transactions/create",
    //     {
    //       method: "POST",
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: "Bearer " + auth.access_token,
    //       },
    //       body: JSON.stringify({
    //         doc_type: formDocSingleData.tipe_dok,
    //         // user_id: id,
    //       }),
    //       redirect: "follow",
    //     }
    //   )
    //     .then((response) => {
    //       //setRincianIsLoading(false);
    //       if (response.status === 401) {
    //         refreshToken();
    //       } else if (response.status === 400) {
    //         swal("Error", "Hubungi Admin", "error");
    //         return response.json()
    //       }  
    //       else if (response.status === 500) {
    //         swal("Error", "Internal Server Error", "error");
    //         return response.json()
    //       } else {
    //         return response.json();
    //       }
    //     })
    //     .then((result) => {
    //         //console.log(result)
    //         let transaction_id = result.data.transaction_id;
    //         cookies.set("transaction_id", transaction_id, { expires: 1 });
    //         console.log("sukses", result.data.transaction_id)
    //         // maybe swal here if success
    //         //swal("Sukses", "Hubungi Admin", "error");
    //         //history.push(`/admin/AktaJualBeli=` + transaction_id);
    //         //window.location.reload();
    //         // if (result.data.transaction_id)
    //         //   uploadDoc(result.data.transaction_id);
    //     })
    //     .catch((error) => { console.log("error", error); setIsLoading(false);});
    
    //   }
    
    return(
        <>
        
            <div className="flex flex-col bg-white w-full mx-auto p-1">
            <h1 className="block text-center text-blue text-xl font-bold mb-2">Rincian Dokumen</h1>
            <div className="text-center text-sm mb-2">Lengkapi form berikut untuk rincian dokumen</div>
                <div className="px-2">
                    <form onSubmit={handleSubmit}>
                        <div className="mb-2">
                           <label className="text-xs">Nomor Dokumen</label> 
                           <input type="text"
                                className="border-0 px-3 py-2 bg-broken-white rounded shadow-input text-sm focus:outline-none w-full ease-linear transition-all duration-150 mb-2"
                                id="nomor_dok"
                                name="nomor_dok"
                                value={formDocSingleData.nomor_dok} 
                                onChange={handleChange}
                                autoComplete="off"
                                 />
                        </div>
                        <div className="mb-2">
                        <label className="text-xs">Nama Dokumen</label> 
                           <label className="text-red-500 px-1">*</label>
                           <input type="text"
                                className="border-0 px-3 py-2 bg-broken-white rounded shadow-input text-sm focus:outline-none w-full ease-linear transition-all duration-150 mb-2"
                                id="nama_dok"
                                name="nama_dok"
                                value={formDocSingleData.nama_dok} 
                                onChange={handleChange}
                                autoComplete="off"
                                required />
                        </div>
                        <div className="mb-4">
                           <label className="text-xs">Tipe Dokumen</label> 
                           <label className="text-red-500 px-1">*</label>
                           <select
                                className="border-1 px-3 py-2 border-gray-400 rounded text-sm shadow-md focus:outline-none w-full"
            name="tipe_dok"
             onChange={handleChange} defaultValue={formDocSingleData.tipe_dok}
            required
          >
            <option disabled >
              Pilih Tipe Dokumen
            </option>
            <option value="dokumen_lain"  >
                  Dokumen Lain-lain
            </option>
            <option value="surat_kuasa" >
                  Surat Kuasa
            </option>
            {/* {dataProv.map((item) => {
              return (
                <option value={item.name} key={item.province_id}>
                  {item.name}
                </option>
              );
            })} */}
          </select>
                        </div>
                        <div className="mb-2">
                            {isLoading ? (
                                <button disabled className="bg-gray rounded w-full p-1">Menyimpan..</button>
                            ) : (
                                <button className="bg-blue rounded w-full text-white p-1">Simpan</button>
                            ) } 
                            
                            
                        </div>
                    </form>
                    
                </div>
            </div>
        </>
    )
}