import React, {useEffect, useState, useContext, Fragment, useRef} from "react";
import { UserContext } from "../../../Context/UserContext";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import Draggable from "react-draggable";
import meteraiImg from "assets/img/signature/meterai.png";

export default function Emeterai3({ goNext }) {
  const {pdf, docMeteraiSingle, setDocMeteraiSingle } = useContext(UserContext);
  const [numPages, setNumPages] = useState(null);
  const [pageHeight, setPageHeight] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageArrHeight, setPageArrHeight] = useState([]);
  const [imgHeightWidth, setImgHeightWidth] = useState({height : 0, width: 0});
  
  function onDocumentLoadSuccess(data) {
      setNumPages(data.numPages);
  }
  // const eMeteraiLoad = (event) =>{
  //   setImgHeightWidth({
  //     height:event.height,
  //     width:event.width,
  //   })
  // }
    const eMeteraiHolder = useRef(null);
    const [bubuhkan, setBubuhkan] = useState(false);
    const handleBubuhkan = () =>{
      setBubuhkan(true);
    }
    const dragHandler = (e, data)=>{
        //console.log('Event Type', e.type);
        console.log({e, data});
        //console.log(e);
        console.log(pageHeight)
        var mypage = 1;
        if (data.y > pageHeight)
          mypage = Math.floor(data.y / pageHeight) + 1;
        if (mypage > 1){
          data.y = data.y - (pageHeight * (mypage-1));
        };
        var docMeterai = {
          page : mypage,
          locX : data.x,
          locY : data.y,
          height: eMeteraiHolder.current.height,
          width: eMeteraiHolder.current.width,
          pageHeight : pageHeight,
        }
        console.log("docEmeterai", docMeterai);
        setDocMeteraiSingle(docMeterai);
    }
  //   function onMouseUp(e, page){
  //     e.preventDefault();
  //     e.stopPropagation();
  //     console.log("mouse up");
  //     console.log(e, page);
  // }
    
    const [showModal, setShowModal] = useState(false);
    const handleModal = () =>{
        console.log("handle Modal");
        if(showModal) {
            setShowModal(false);
          }  else {
            setShowModal(true)
        }
    }
    const handleSetuju = () => {
      handleModal();
      setBubuhkan(false);
      goNext("step4")
    }
    const styles = {
      container: {
        position: 'absolute',
        zIndex: 3,
        border: `2px solid #97bace`,
      },
      controls: {
        position: 'absolute',
        right: 0,
        display: 'inline-block',
        backgroundColor: '#97bace',
        // borderRadius: 4,
      },
      smallButton: {
        display: 'inline-block',
        cursor: 'pointer',
        padding: 4,
      }
    }
    return(
        <>
        <div className="flex flex-col bg-white w-full mx-auto p-1">
            <h1 className="block text-center text-blue text-xl font-bold mb-2">Pembubuhan E-meterai</h1>
            <div className="text-center text-sm mb-2">Drag and Drop E-meterai</div>
            <div className="px-2">
                <div className="w-full flex mb-2">
                  { bubuhkan ? (
                    <>
                      <button 
                        onClick={handleModal}
                        className="rounded bg-green text-white p-2 mr-2">
                        <i className="fa fa-check mr-2"></i>Selesai
                      </button>
                      <button 
                        className="rounded bg-red-400 text-white p-2 mr-2">
                       <i className="fa fa-times mr-2"></i>Gagal
                      </button>
                      </>
                  ) : (
                    <button 
                      className="rounded bg-blue text-white p-2 mr-2"
                      onClick={handleBubuhkan}
                      >
                        <i className="fa fa-plus mr-2"></i>Bubuhkan E-Meterai
                    </button>
                  )}
                    <span className="rounded-xl border-2  border-blue-500 p-2 mr-2">Kuota <span className="text-bold">5</span> e-meterai</span>
                    <button
                    className="rounded-xl border-2 border-solid border-blue-500  p-2 mr-2 "
                      > <i className="fa fa-info-circle mr-2"></i>Detail</button>
                </div>
            {/* <div className="justify-center h-250-px w-full border-2 border-blue-400 bg-blueGray-200 pt-2 border-dashed rounded">
            </div> */}
            <div className="">
              {/* Draggable e-Meterai*/}
              { bubuhkan && (
              <Draggable
                bounds="parent"
                position={null}
                defaultPosition={{ x: 0, y: 0 }}
                //onMouseDown={dragHandler}
                //onStart={dragHandler}
                //onDrag={dragHandler}
                onStop={dragHandler}
              >
                <div 
                    //ref={nodeRef}
                    //className="handle z-2 m-auto select-none flex-row-reverse text-center text-lg font-bold opacity-80 cursor-pointer hover:brightness-50"
                    style={styles.container}
                  >
                <img 
                            ref={eMeteraiHolder}
                            src={meteraiImg}
                            id="meterai"
                            className=""
                            alt="meterai"
                            //onLoad={eMeteraiLoad}
                            //style={{zIndex : "10001"}}
                        />
              </div>
            </Draggable>
            ) } 
                {pdf && (
                    <Document 
                      
                      renderMode="canvas"
                      className="border"
                      file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
                        
                         {Array.from(new Array(numPages), (el, index) => (
                          <Fragment key={`fragment_${index + 1}`}>
                            <div 
                              className="relative mx-auto w-fit"
                              // onMouseDown={(e) => {
                              //   if (e.button === 0) onMouseDown(e, index);
                              //   }}
                                // onMouseUp={(e) => {
                                // if (e.button === 0) onMouseUp(e, index);
                                // }}
                              >
                            <Page 
                                className="border"
                                //pageNumber={pageNumber} 
                                key={`page_${index + 1}`} 
                                pageNumber={index + 1}
                                renderAnnotationLayer={false}
                                renderTextLayer={false}
                                onLoadSuccess={(e) => {
                                    if (e.height) { 
                                      setPageHeight(e.height); 
                                      setPageArrHeight({ page : index + 1, height : e.height });
                                    }
                                    console.log(e.height);
                                    
                                    //if (e.width) setPageHeight(e.width);
                                    //setLoading(false);
                                }}
                            />
                            
                            </div>
                          </Fragment>
                            
                         ))}
                    </Document>
                    
                )}
            </div>
            </div>
        </div>
        {/* MODAL */}
        {showModal && (
            <>
            <div id="modal_emeterai3_overlay"
            className="test aja justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none"
            >
              {/* CONTENT */}
            <div id="modal_emeterai3" className="relative w-auto mx-auto max-w-lg">
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <button 
            onClick={handleModal}
              className="absolute -top-3 -right-3 bg-red-500 hover:bg-red-600 text-2xl w-10 h-10 rounded-full focus:outline-none text-white">
              <i className="fa fa-times"></i>
            </button>
                {/*header*/}
                <div className="flex items-start justify-center p-2 rounded-t">
                  <h1 className="text-xl font-semibold text-blue-500 ">
                    Konfirmasi e-Meterai
                  </h1>
                </div>
                  {/*body*/}
              <div>
              <p className="my-1 mx-6  text-md text-center">
                  Saya Menyetujui pembubuhan e-Meterai untuk dokumen ini
                </p>
              </div>
                  {/* footer */}
              <div className="flex justify-center pb-2">
              <button onClick={handleModal}
                className="rounded bg-blueGray-200 p-2 mr-2">
                          <i className="fa fa-times mr-2"></i>Tidak</button>
                   <button className="rounded bg-blue hover:bg-blue-500 text-white p-2 mr-2"
                    onClick={handleSetuju}
                   >
                          <i className="fa fa-check mr-2"></i>Setuju</button>
                      
              </div>
              </div>
            </div>
          </div>
          <div  className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        )}
        
        
        </>
    )
}