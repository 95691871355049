import React, {useState, useContext, useEffect} from "react";
import { UserContext } from "../../../Context/UserContext";
import { MyAjbContext } from "../../../Context/AjbContext";
//import cookies from "js-cookie";
import swal from "sweetalert";
import ComponentModalOtp from "./ComponentModalOtp"

export default function CompModalKonfirmasi({setshow}) {
    const { ttdImage, inputAjb, setInputAjb, functions } = useContext(MyAjbContext);
    const { refreshToken} = functions;
    const {pdf, docMeteraiSingle, docTtdSingle,formDocSingleData,pdfBase64} = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const [rincianIsLoading, setRincianIsLoading] = useState(false);
    const [unggahIsLoading, setUnggahIsLoading] = useState(false);
    //const [genSnIsLoading, setGenSnIsLoading] = useState(false);
    const [emeteraiIsLoading, setEmeteraiIsLoading] = useState(false);
    const [ttdIsLoading, setTtdIsLoading] = useState(false);
    const [otpShow, setOtpShow] = useState(false);
    const [signid, setSignid] = useState("");
    const [trid, setTrid] = useState(null);
    const authDataJson = localStorage.getItem("authentication");
    const auth = JSON.parse(authDataJson); 

    useEffect(()=>{
      //cekQuota("emeterai");
      createDocument(formDocSingleData);
      //setOtpShow(true);
      //handleSignid("1234");
      // console.log({llx: docMeteraiSingle.locX,
      //   lly: docMeteraiSingle.pageHeight - (docMeteraiSingle.locY + docMeteraiSingle.height) ,
      //   urx: docMeteraiSingle.locX + docMeteraiSingle.width,
      //   ury: docMeteraiSingle.pageHeight - docMeteraiSingle.locY,
      //   page: docMeteraiSingle.page});
      //uploadDoc();
      // eslint-disable-next-line
    },[])

    const handleOTP = (truefalse, otpvalue, signid)=>{
      //alert(signid);
      console.log("otpvalue", otpvalue);
      console.log("signid", signid);
      otpTandaTangan(trid,otpvalue,signid );
      setOtpShow(truefalse);
    }

    const handleSignid = (value)=>{
      setSignid(value);
    }

    const createDocument = async (formDocSingleData) => {
        setRincianIsLoading(true);
        await fetch(
          process.env.REACT_APP_BACKEND_HOST_TRANSACTION +
            "/api/transactions/create",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + auth.access_token,
            },
            body: JSON.stringify({
              doc_type: formDocSingleData.tipe_dok,
              doc_num: formDocSingleData.nomor_dok,
              doc_name: formDocSingleData.nama_dok,
              // user_id: id,
            }),
            redirect: "follow",
          }
        )
          .then((response) => {
            setRincianIsLoading(false);
            if (response.status === 401) {
              refreshToken();
            } else if (response.status === 400) {
              swal("Error", "Hubungi Admin", "error");
              return response.json()
            }  
            else if (response.status === 500) {
              swal("Error", "Internal Server Error", "error");
              return response.json()
            } else {
              return response.json();
            }
          })
          .then((result) => {
              //console.log(result)
              // let transaction_id = result.data.transaction_id;
              // cookies.set("transaction_id", transaction_id, { expires: 1 });
              console.log("sukses", result.data.transaction_id);
              setTrid(result.data.transaction_id);
              // maybe swal here if success
              //swal("Sukses", "Hubungi Admin", "error");
              //history.push(`/admin/AktaJualBeli=` + transaction_id);
              //window.location.reload();
              if (result.data.transaction_id)
                uploadDoc(result.data.transaction_id);
          })
          .catch((error) => { console.log("error", error); setIsLoading(false);});
      
        }
    
    const uploadDoc = async (tr_id) => {
      /* transaction_id, doc_type, base64_doc */
      let myHeaders = new Headers();
      //myHeaders.append("Cookie", "REVEL_FLASH=");
      myHeaders.append("Authorization", "Bearer " + auth.access_token);
      let formdata = new FormData();
      //formdata.append("transaction_id", cookies.get("uid"));
      formdata.append("transaction_id", tr_id);
      formdata.append("doc_type", formDocSingleData.tipe_dok);
      formdata.append("base64_doc",pdfBase64);

      let requestOptions = {
        method: "POST",
        credentials: "same-origin",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      //var id_tr = cookies.get("transaction_id");
      //console.log(id_tr);
      // change files into base64
      setUnggahIsLoading(true);
       await fetch(
        process.env.REACT_APP_BACKEND_HOST_TRANSACTION +
          "/api/transactions/transactions_docs",
        requestOptions
      )
        .then((response) => {
          //setIsLoading(false);
          if (response.status === 401) {
            refreshToken();
          } else if (response.status === 400) {
            swal("Error", "Hubungi Admin", "error");
            return response.json()
          }  
          else if (response.status === 500) {
            swal("Error", "Internal Server Error", "error");
            return response.json()
          } else {
            return response.json();
          }
        })
        .then((result) => {
            //console.log(result)
            setUnggahIsLoading(false);
            if(result.success)
              generateSN(tr_id);
            // let transaction_id = result.data.transaction_id;
            // cookies.set("transaction_id", transaction_id, { expires: 1 });
            //console.log("sukses", transaction_id)
            // maybe swal here if success
            //swal("Sukses", "Hubungi Admin", "error");
            //history.push(`/admin/AktaJualBeli=` + transaction_id);
            //window.location.reload();
            // if (result.data.transaction_id)
            //   uploadDoc(result.data.transaction_id);
        })
        .catch((error) => { console.log("error", error); setIsLoading(false);});
    }
    // generate sn 
    const generateSN = async (tr_id) => {
      setEmeteraiIsLoading(true);
      await fetch(
        process.env.REACT_APP_BACKEND_HOST_TRANSACTION +
          "/api/transactions/generate-sn",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.access_token,
          },
          body: JSON.stringify({
            transaction_id: tr_id,
            // user_id: id,
          }),
          redirect: "follow",
        }
      )
        .then((response) => {
          
          if (response.status === 401) {
            refreshToken();
          } else if (response.status === 400) {
            swal("Error", "Hubungi Admin", "error");
            return response.json()
          }  
          else if (response.status === 500) {
            swal("Error", "Internal Server Error", "error");
            return response.json()
          } else {
            return response.json();
          }
        })
        .then((result) => {
            // generate sn done
            if(result.success)
              pembubuhanEmeterai(tr_id);
        })
        .catch((error) => { console.log("error", error); setEmeteraiIsLoading(false);});
    }

    // api : stamp-cloud
    const pembubuhanEmeterai = async (tr_id) => {
      
      await fetch(
        process.env.REACT_APP_BACKEND_HOST_TRANSACTION +
          "/api/transactions/stamp-cloud",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.access_token,
          },
          body: JSON.stringify({
            transaction_id: tr_id,
            //docMeteraiSingle
            llx: docMeteraiSingle.locX,
            lly: docMeteraiSingle.pageHeight - (docMeteraiSingle.locY + docMeteraiSingle.height) ,
            urx: docMeteraiSingle.locX + docMeteraiSingle.width,
            ury: docMeteraiSingle.pageHeight - docMeteraiSingle.locY,
            page: docMeteraiSingle.page
          }),
          redirect: "follow",
        }
      )
        .then((response) => {
          //setEmeteraiIsLoading(false);
          if (response.status === 401) {
            refreshToken();
          } else if (response.status === 400) {
            swal("Error", "Hubungi Admin", "error");
            return response.json()
          }  
          else if (response.status === 500) {
            swal("Error", "Internal Server Error", "error");
            return response.json()
          } else {
            return response.json();
          }
        })
        .then((result) => {
            // pembubuhan e-meterai done
            setEmeteraiIsLoading(false);
            if(result.success)
             pembubuhanTandaTangan(tr_id);
        })
        .catch((error) => { console.log("error", error); setEmeteraiIsLoading(false);});

    }

    // using api
    const pembubuhanTandaTangan = async (tr_id) => {
      setTtdIsLoading(true);
      await fetch(
        process.env.REACT_APP_BACKEND_HOST_TRANSACTION +
          "/api/transactions/sign-doc/step-1",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.access_token,
          },
          body: JSON.stringify({
            transaction_id: tr_id,
            // user_id: id,
            llx: docTtdSingle.locX,
            lly: docTtdSingle.pageHeight - (docTtdSingle.locY + docTtdSingle.height) ,
            urx: docTtdSingle.locX + docTtdSingle.width,
            ury: docTtdSingle.pageHeight - docTtdSingle.locY,
            page: docTtdSingle.page
          }),
          redirect: "follow",
        }
      )
        .then((response) => {
          //setEmeteraiIsLoading(false);
          if (response.status === 401) {
            refreshToken();
          } else if (response.status === 400) {
            swal("Error", "Hubungi Admin", "error");
            return response.json()
          }  
          else if (response.status === 500) {
            swal("Error", "Internal Server Error", "error");
            return response.json()
          } else {
            return response.json();
          }
        })
        .then((result) => {
            // generate sn done
            if(result.success){
              setOtpShow(true);
              handleSignid(result.data.sign_doc_id);
              //result.data.sign_doc_id
              
            }
              
        })
        .catch((error) => { console.log("error", error); setEmeteraiIsLoading(false);});
    }

    // belum ada api
    const otpTandaTangan = async (tr_id,otpvalue,signid) => {
      await fetch(
        process.env.REACT_APP_BACKEND_HOST_TRANSACTION +
          "/api/transactions/sign-doc/step-2",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.access_token,
          },
          body: JSON.stringify({
            transaction_id: tr_id,
            otp_code:otpvalue,
            sign_doc_id:signid,
          }),
          redirect: "follow",
        }
      )
        .then((response) => {
          setTtdIsLoading(false);
          if (response.status === 401) {
            refreshToken();
          } else if (response.status === 400) {
            swal("Error", "Hubungi Admin", "error");
            return response.json()
          }  
          else if (response.status === 500) {
            swal("Error", "Internal Server Error", "error");
            return response.json()
          } else {
            return response.json();
          }
        })
        .then((result) => {
            // generate sn done
        })
        .catch((error) => { console.log("error", error); setEmeteraiIsLoading(false);});

    }

    const handleSelesai = ()=>{
        //setRincianIsLoading(false);
        //setUnggahIsLoading(false);
        //console.log("pdf64",pdfBase64)
        setshow(false);
    }
    return (<>
        {otpShow ? (<>
          <ComponentModalOtp setThisShow={handleOTP} thisSignid={signid} />
        </>) : (<></>)}
        <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none"
            //   onClick={() => setOtpModal(false)}
          >
            <div className="relative w-auto my-2 mx-auto bg-gray rounded-lg">
              {/*content*/}
              {/* <form> */}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white p-2 ">
                {/*body*/}
                <div className="relative p-2 px-10 flex-col text-blue font-roboto">
                  <label className="block text-center text-blue text-xl font-bold ">
                    Proses Server 
                  </label>
                  <label className="text-md text-black block text-center ">
                  Mohon Tunggu
                  </label>
                  <div className="">
                    <ol className="relative border-l border-gray-200">
                        <li className="mb-5 ml-6 li-list-item">
                        {rincianIsLoading ? (<>
                            <span className="absolute flex items-center justify-center w-8 h-8 bg-green-200 -left-4 border-t-transparent border-solid animate-spin  rounded-full border-blue-400 border-2">
                                <i className="fa fa-save"></i>
                            </span>
                            <h3 className="text-black">Menyimpan Rincian Dokumen</h3>
                            <p className="text-sm text-yellow">...Loading</p>
                        </>) : (<>
                            <span className="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -left-4">
                                <i className="fa fa-save"></i>
                            </span>
                            <h3 className="text-black">Rincian Dokumen</h3>
                            
                        </>)}
                            
                        </li>
                        <li className="mb-5 ml-6 li-list-item">
                            { unggahIsLoading ? (<>
                                <span className="absolute flex items-center justify-center w-8 h-8 bg-green-200 -left-4 border-t-transparent border-solid animate-spin  rounded-full border-blue-400 border-2">
                                    <i className="fa fa-upload"></i>
                                </span>
                                <h3 className="text-black">Unggah Dokumen</h3>
                                <p className="text-sm">...Loading</p>
                            </>) : (<>
                                <span className="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -left-4">
                                    <i className="fa fa-upload"></i>
                                </span>
                                <h3 className="text-black">Unggah Dokumen</h3>
                                
                            </>)}
                        </li>
                        <li className="mb-5 ml-6 li-list-item">
                        { emeteraiIsLoading ? (<>
                          <span className="absolute flex items-center justify-center w-8 h-8 bg-green-200 -left-4 border-t-transparent border-solid animate-spin  rounded-full border-blue-400 border-2">
                                <i className="fa fa-stamp"></i>
                            </span>
                            <h3 className="text-black">Bubuhkan e-Meterai</h3>
                            <p className="text-sm">...Loading</p>
                        </>) : (<>
                          <span className="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -left-4">
                                <i className="fa fa-stamp"></i>
                            </span>
                            <h3 className="text-black">Bubuhkan e-Meterai</h3>
                        </>)}
                        </li>
                        <li className="mb-5 ml-6 li-list-item">
                          { ttdIsLoading ? (<>
                            <span className="absolute flex items-center justify-center w-8 h-8 bg-green-200 -left-4 border-t-transparent border-solid animate-spin  rounded-full border-blue-400 border-2">
                                <i className="fa fa-pen"></i>
                            </span>
                            <h3 className="text-black">Bubuhkan Tanda Tangan</h3>
                            <p className="text-sm">Menunggu</p>
                          </>) : (<>
                            <span className="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -left-4">
                                <i className="fa fa-pen"></i>
                            </span>
                            <h3 className="text-black">Bubuhkan Tanda Tangan</h3>
                          </>)}
                            
                        </li>
                        <li className="ml-6 li-list-item">
                        { ttdIsLoading ? (<>
                            <span className="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -left-4">
                                <i className="fa fa-phone"></i>
                            </span>
                            <h3 className="text-black">Input OTP</h3>
                            <p className="text-sm">Menunggu</p>
                            </>) : (<>
                              <span className="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -left-4">
                                <i className="fa fa-phone"></i>
                            </span>
                            <h3 className="text-black">Input OTP</h3>
                            </>)}
                        </li>
                    </ol>
                    
                  </div>
                  
                </div>
                
            </div>
            <button
                onClick={handleSelesai}
                className="text-white float-right cursor-pointer text-xs bg-blue mt-2 border-blue rounded-lg background-transparent font-bold px-4 py-2 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              ><i className="fa fa-check mr-2"></i>
                Selesai
              </button>
            </div>
        </div>
        <div  className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>)
}