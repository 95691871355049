import React from "react";
// import "./MultiStepProgressBar.css";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";

const MultiStepProgressBar = (props) => {
  var stepPercentage = 0;

  if (props.currentStep === "step1") {
    stepPercentage = 0;
  } else if (props.currentStep === "step2") {
    stepPercentage = 25;
  } else if (props.currentStep === "step3") {
    stepPercentage = 50;
  } else if (props.currentStep === "step4") {
    stepPercentage = 75;
  } else if (props.currentStep === "step5") {
    stepPercentage = 100;
  } else {
    stepPercentage = 0;
  }

  return (
    <ProgressBar percent={stepPercentage}>
      <Step>
        {({ accomplished, index }) => (
          <>
            <div
              className={`indexedStep ${accomplished ? "accomplished" : null}`}
              onClick={() => props.goNext("step1")}
            >
              {index + 1}
            </div>
            <div 
              className="absolute text-xs w-36 text-center text-blue"  
              style={{ top:"20px"}}
            >
              Rincian Dokumen
            </div>
          </>
        )}
      </Step>
      <Step>
        {({ accomplished, index }) => (
          <>
            <div
              className={`indexedStep ${accomplished ? "accomplished" : null}`}
              onClick={() => props.goNext("step2")}
            >
              {index + 1}
            </div>
            <div className="absolute text-xs w-36 text-center text-blue"  
              style={{ top:"20px"}}>
              Unggah Dokumen
            </div>
          </>
        )}
      </Step>
      <Step>
        {({ accomplished, index }) => (
          <>
            <div
              className={`indexedStep ${accomplished ? "accomplished" : null}`}
              onClick={() => props.goNext("step3")}
            >
              {index + 1}
            </div>
            <div className="absolute text-xs w-36 text-center text-blue"  
              style={{ top:"20px"}}>
              Pembubuhan e-Meterai
            </div>
          </>
        )}
      </Step>
      <Step>
        {({ accomplished, index }) => (
          <>
            <div
              className={`indexedStep ${accomplished ? "accomplished" : null}`}
              onClick={() => props.goNext("step4")}
            >
              {index + 1}
            </div>
            <div className="absolute text-xs w-36 text-center text-blue"  
              style={{ top:"20px"}}>
            Pembubuhan Tanda Tangan
            </div>
          </>
        )}
      </Step>
      <Step>
        {({ accomplished, index }) => (
          <>
            <div
              className={`indexedStep ${accomplished ? "accomplished" : null}`}
              onClick={() => props.goNext("step5")}
            >
              {index + 1}
            </div>
            <div className="absolute text-xs w-36 text-center text-blue"  
              style={{ top:"20px"}}>
            Review Order
            </div>
          </>
        )}
      </Step>
    </ProgressBar>
  );
};

export default MultiStepProgressBar;
