import React, {useState, useContext, Fragment, useRef} from "react";
import { UserContext } from "../../../Context/UserContext";
import { MyAjbContext } from "../../../Context/AjbContext";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
//import Draggable from "react-draggable";
import meteraiImg from "assets/img/signature/meterai.png";
import CompModalKonfirmasi from "./CompModalKonfirmasi";



export default function Review5() {
    const { ttdImage, inputAjb, setInputAjb } = useContext(MyAjbContext);
    //const { refreshToken} = functions;
    const {pdf, docMeteraiSingle, docTtdSingle,formDocSingleData} = useContext(UserContext);
    const [numPages, setNumPages] = useState(null);
    const [pageHeight, setPageHeight] = useState(0);
    const elMaterai = useRef(null);
    const [showLoading, setShowLoading] = useState(false);
    //const [isLoading, setIsLoading] = useState(false);
    function onDocumentLoadSuccess(data) {
        setNumPages(data.numPages);
        setInputAjb({
            urx : docMeteraiSingle.locX ,
            ury : docMeteraiSingle.locY,
            llx : docMeteraiSingle.locX +  elMaterai.current.offsetWidth,
            lly : docMeteraiSingle.locY +  elMaterai.current.offsetHeight,
            page : docMeteraiSingle.page
        })
    }
    // useEffect(() => {
        
    // },[]);
    const handleSelesai = ()=>{
        console.log(inputAjb);
        setShowLoading(true);
        // test transaction id : 9a375eba-a7de-446c-ac72-844015e2f069
        //var transid = "9a375eba-a7de-446c-ac72-844015e2f069"
        
        //addMeterai(transid);
    }
    const setShow = (truefalse)=>{
        setShowLoading(truefalse);
    }
    
    return(<>
    <div className="flex flex-col bg-white w-full mx-auto p-1">
        <h1 className="block text-center text-blue text-xl font-bold mb-2">Review</h1>
        <div className="text-center text-sm mb-2">Review Dokumen</div>
        
        <div className="flex flex-col rounded-xl p-4 justify-center items-center text-center">
            <div>
                <label className="mr-1 text-bold">Nomor Dokumen : </label><span>{formDocSingleData.nomor_dok}</span>
            </div>
            <div>
            <label className="mr-1 text-bold">Nama Dokumen :</label> <span>{formDocSingleData.nama_dok}</span>
            </div>
            <div>
            <label className="mr-1 text-bold">Tipe Dokumen :</label><span>{formDocSingleData.tipe_dok}</span>
            </div>
            <div >
                <button 
                    onClick={handleSelesai}
                    className="rounded bg-green text-white p-2 mr-2">
                    <i className="fa fa-check mr-2"></i>Selesai
                </button>
                </div>
        </div>
        <div className="">
        {pdf && (
                    <Document 
                      renderMode="canvas"
                      className="border"
                      file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
                         {Array.from(new Array(numPages), (el, index) => (
                          <Fragment key={`fragment_${index + 1}`}>
                            <div 
                              className="relative mx-auto w-fit"
                              >
                            <Page 
                                className="border"
                                //pageNumber={pageNumber} 
                                key={`page_${index + 1}`} 
                                pageNumber={index + 1}
                                renderAnnotationLayer={false}
                                renderTextLayer={false}
                                onLoadSuccess={(e) => {
                                    if (e.height) { 
                                      setPageHeight(e.height); 
                                    }
                                }}
                            />
                        { docMeteraiSingle && (
                            <>
                            { (docMeteraiSingle.page === (index+1)) ? (
                                <img 
                                     src={meteraiImg}
                                     ref={elMaterai}
                                     id="meterai"
                                     className="absolute "
                                     alt="meterai"
                                     style={{
                                        zIndex : "3", 
                                        top: docMeteraiSingle.locY, 
                                        left:docMeteraiSingle.locX,
                                        
                                }}
                                 />  
                            ) : ("") }
                            </>  
                        )}

{ docTtdSingle && (
                            <>
                            { (docTtdSingle.page === (index+1)) ? (
                                <img 
                                     src={ttdImage}
                                     id="ttdImage4"
                                     className="absolute "
                                     alt="ttdImage4"
                                     style={{
                                        zIndex : "3", 
                                        top: docTtdSingle.locY, 
                                        left:docTtdSingle.locX
                                }}
                                 />  
                            ) : ("") }
                            </>  
                        )}
                        
                           
                            </div>
                          </Fragment>
                            
                         ))}
                    </Document>
                    
                )}
        </div>
    </div>
    { showLoading && <CompModalKonfirmasi 
        setshow={setShow} 
        formData={formDocSingleData} 
        //createDoc={createDocument}
        /> }
    </>)
}