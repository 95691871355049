import React from "react";
import { MyAjbContext } from "Context/AjbContext";
import { useContext } from "react";
import ModalDokumen from "components/Modals/ModalDokumen";

export const ListDataComponent = () => {
  const { isOpenTableNik, functions, LoadingFile } = useContext(MyAjbContext);
  const { closeTableNikData } = functions;

  const TABLE_ROWS = [
    {
      no: "1",
      name: "John Michael",
      email: "john@creative-tim.com",
      nik: "Manager",
    },
    {
      no: "2",
      name: "Alexa Liras",
      email: "alexa@creative-tim.com",
      nik: "23293525252395399",
    },
    {
      no: "3",
      name: "Laurent Perrier",
      email: "laurent@creative-tim.com",
      nik: "Executive",
    },
    {
      no: "4",
      name: "Michael Levi",
      email: "michael@creative-tim.com",
      nik: "424296224511154539",
    },
    {
      no: "5",
      name: "Richard Gran",
      email: "richard@creative-tim.com",
      nik: "3213410144131399",
    },
  ];

  return (
    <div>
      {isOpenTableNik ? (
        <>
          {/* <div className="testingaja justify-center cursor-default font-sans items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-2 mx-auto font-roboto">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-700-d bg-white outline-none focus:outline-none">
                {LoadingFile && <ModalDokumen />}
                <div className="flex ">
                  <button
                    className="focus:outline-none pr-3 mt-1"
                    type="button"
                    style={{ position: "absolute", top: 0, right: 0 }}
                    onClick={closeTableNikData}
                  >
                    <i className="fa fa-times fa-lg"></i>
                  </button>
                </div>
                <div className="flex flex-col items-center justify-center">
                  <label className="block text-20px font-bold mb-2 mt-6 text-blue">
                    Pencarian Akun
                  </label>
                  <label className="block text-xs text-black mb-5">
                    Temukan Akun yang sudah terdaftar
                  </label>
                </div>
                <div className="mb-2 py-2 px-3 flex  justify-between ">
                  {/* <div className="flex w-full justify-between"> */}
          {/* <input
            type="text"
            className="border-0 bg-broken-white rounded shadow-input text-sm focus:outline-none w-full ease-linear transition-all duration-150"
            name="nik_penjual"
            // onChange={handleChange}
            autoComplete="off"
            required
          />
          <button
            className="focus:outline-none"
            type="button"
            //  style={{ position: "absolute", top: 0, right: 0 }}
            onClick={closeTableNikData}
          >
            <i className={"fa fa-search search-nik bg-blue"}></i>
          </button> */}
          {/* </div>
                <div className="flex w-full justify-between">
                <input
                    type="text"
                    className="border-0 bg-broken-white rounded shadow-input text-sm focus:outline-none w-full ease-linear transition-all duration-150"
                    name="nik_penjual"
                    // onChange={handleChange}
                    autoComplete="off"
                    required
                  />
                  <button
                    className="focus:outline-none"
                    type="button"
                    //  style={{ position: "absolute", top: 0, right: 0 }}
                    onClick={closeTableNikData}
                  >
                    <i className={"fa fa-search search-nik bg-blue"}></i>
                  </button>
                </div>
              </div>
            </div> */}
          {/* </div> */}
          <div className="test aja justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none">
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-700-d bg-white outline-none focus:outline-none">
              <div className="relative w-auto mx-auto">
                {/* <div className="relative w-full mb-3"> */}
                <div className="flex flex-col items-center justify-center  ">
                  <button
                    className="focus:outline-none mt-2"
                    type="button"
                    style={{ position: "absolute", top: 0, right: 0 }}
                    onClick={closeTableNikData}
                  >
                    <i className="fa fa-times fa-lg"></i>
                  </button>
                  <label
                    className="block text-20px font-bold mb-2 mt-5 text-blue"
                    htmlFor="grid-password"
                  >
                    Pencarian Akun
                  </label>
                  <label
                    className="block text-xs mb-5 text-black"
                    htmlFor="grid-password"
                  >
                    Temukan Akun yang sudah terdaftar
                  </label>
                </div>
                <label className="text-xs">
                  Nomor Induk Kependudukan (NIK)
                </label>
                <label className="text-red-500 px-1">*</label>
                <input
                  maxLength={16}
                  type="search"
                  className="border-0 px-3 py-2 bg-broken-white rounded shadow-input text-sm focus:outline-none w-full ease-linear transition-all duration-150 mb-2"
                  name="nik_penjual"
                  placeholder="Masukan NIK Anda..."
                  // onChange={handleChange}
                  autoComplete="off"
                  required
                />
                <span class="absolute inset-y-0 right-0 flex items-center pl-2">
                  <button
                    type="submit"
                    class="p-1 focus:outline-none focus:shadow-outline"
                  >
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                      class="w-6 h-6"
                    >
                      <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                    </svg>
                  </button>
                </span>
                <button
                  type="button"
                  // onClick={openTable}
                  className="showPass focus:outline-none"
                >
                  <i className={"fa fa-search search-nik bg-blue"}></i>
                </button>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </div>
  );
};
