import React, { useContext, useEffect, useState } from "react";

// components
import UserInfo from "components/Cards/UserInfo.js";

// Context
import { UserContext } from "Context/UserContext";
import cookies from "js-cookie";
import ModalDokumen from "components/Modals/ModalDokumen";
import RiwayatPemohon from "components/Cards/RiwayatPemohon";
// import { io } from "socket.io-client";
// import swal from "sweetalert";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import LengkapiDiriCard from "components/Cards/LengkapiDiriCard";

export default function Dashboard() {
  const {  setLengkapidiri, lengkapidiri, loading } = useContext(UserContext);

  const [userFiles, setUserFiles] = useState(false);

  var val = localStorage.getItem("dataPPAT");
  var object = JSON.parse(val);
  
  useEffect(() => {
      
    if (
      object.role === "member" &&
      object.user_files.bpjs &&
      object.user_files.ktp &&
      object.user_files.npwp &&
      object.user_files.self_video &&
      object.user_files.selfie_photo &&
      !object.user_files.sk_pengangkatan &&
      object.user_files.ttd
    ) {
      setUserFiles(true);
    } else if (
      object.role === "ppat" &&
      object.user_files.bpjs &&
      object.user_files.ktp &&
      object.user_files.npwp &&
      object.user_files.self_video &&
      object.user_files.selfie_photo &&
      object.user_files.sk_pengangkatan &&
      object.user_files.ttd
    ) {
      setUserFiles(true);
    }
   // eslint-disable-next-line
  }, [userFiles]);

  //if (!object.user_detail && userFiles === false) {
  if (!object.user_detail && userFiles === false) {
    setLengkapidiri(true);
  }

  // const userDetails = Object.keys(object.user_detail).length !== 0;
  // const userFile = Object.values(object.user_files).map((el) => el);
  // let isUserFile = userFile
  //   .filter((el) => el.toString() === "false")
  //   .map((el) => el.toString());

  // useEffect(() => {
    // if (isUserFile.length !== 0) {
    //   setUserFiles(true);
    // } else {
    //   setUserFiles(false);
    // }
  // });

  // if (userFiles === true) {
  //   setLengkapidiri(true);
  // }

  // const userFile = Object.values(object.user_files).map((el) => el);
  // let isUserFile = userFile
  //   .filter((el) => el.toString() === "false")
  //   .map((el) => el.toString());

  // useEffect(() => {
  //   if (isUserFile.length === 0) {
  //     setUserFiles(true);
  //   } else {
  //     setUserFiles(false);
  //   }
  // });

  // if (Object.keys(object.user_detail).length === 0 || userFiles === false) {
  //   setLengkapidiri(true);
  // }

  //console.log(userFiles);
  cookies.remove("id_transaksi");
  cookies.remove("step");
  cookies.remove("otp_sms");
  cookies.remove("phone");
  cookies.remove("tipe_otp");
  localStorage.removeItem("dataDiri");

  return (
    <>
      {loading && <ModalDokumen />}
      {lengkapidiri && <LengkapiDiriCard />}
      <div className="flex flex-wrap cursor-default">
        <div className="w-full xl:w-12/12 mb-8 xl:mb-2 px-1">
          <UserInfo dataUser={object} />
        </div>
        <div className="w-full xl:w-12/12 mb-8 xl:mb-2 px-1">
          <RiwayatPemohon dataUser={object} />
        </div>
      </div>
    </>
  );
}
