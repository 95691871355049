import React, { useContext, useEffect, useState } from "react";
import { RegistContext } from "./RegistContext";
import { Link, useLocation } from "react-router-dom";
import queryString from "query-string";
import Email from "../../assets/img/emailscek.png";
import isKadaluarsa from "../../assets/img/browser1.png";
import isValid from "../../assets/img/browser(1).png";

export default function VerifiyEmail() {
  const location = useLocation();

  //var auth = localStorage.getItem("authentication");
  //var token = JSON.parse(auth);

  const { email, verify_code } = queryString.parse(location.search);
  const { refreshToken, resendEmailRegist } = useContext(RegistContext);

  const [go, setGo] = useState(false);
  //Judul
  const [title, setTitle] = useState("");

  //Sub-judul True
  const [getRespon, setGetRespon] = useState("");
  //Sub-judul False
  const [sub, setSub] = useState(false);
  const errorDiv = sub ? (
    <div className="text-center mt-4 mb-6">
      Sepertinya tautan verifikasi telah kedaluwarsa. <br /> Jangan khawatir,
      kami dapat mengirimkan tautannya lagi
    </div>
  ) : (
    <div className="text-center mt-4 mb-6d">
      Tautan verifikasi tidak valid. <br /> Mohon gunakan tautan verifikasi yang
      sesuai dengan yang Anda terima di email Anda.
    </div>
  );

  //True-false Image
  const [image, setImage] = useState(false);

  //Button True
  const [respon, setRespon] = useState("");

  //Button False
  const [errors, setErrors] = useState("");

  useEffect(() => {
    let myHeaders = new Headers();
    myHeaders.append("Cookies", "REVEL_FLASH=");
    // myHeaders.append("Authorization", "Bearer " + token.access_token);

    fetch(
      process.env.REACT_APP_BACKEND_HOST_AUTH +
        "api/auth/register/verify-email?email=" +
        email +
        "&verify_code=" +
        verify_code,
      { method: "GET" }
    )
      .then((ress) => {
        if (ress.status === 401) {
          refreshToken();
        } else {
          return ress.json();
        }
      })
      .then((res) => {
        //console.log(res);
        if (res.success === true) {
          if (res.data.message === "Email berhasil diverifikasi!") {
            setGo(true);
            setTitle("Verifikasi Berhasil");
            setGetRespon("Selamat, email Anda sudah berhasil terverifikasi");
            setRespon("Masuk");
          } else if (res.data.message === "email sudah terverifikasi") {
            setGo(true);
            setTitle("Verifikasi Berhasil");
            setGetRespon("Selamat, email Anda sudah berhasil terverifikasi");
            setRespon("Masuk");
          }
        } else {
          if (res.error === "Kode verifikasi kedaluwarsa.") {
            setGo(false);
            setImage(true);
            setTitle("Tautan Verifikasi Email kedaluwarsa");
            // setGetRespon(
            //   "Sepertinya tautan verifikasi telah kedaluwarsa.\n Jangan khawatir, kami dapat mengirimkan tautannya lagi"
            // );
            setSub(true);
            setErrors("Kirim Ulang");
          } else if (res.error === "Kode verifikasi tidak valid.") {
            setGo(false);
            setImage(false);
            setTitle("Verifikasi Email Gagal");
            // setGetRespon(
            //   "Tautan verifikasi tidak valid.\n Mohon gunakan tautan verifikasi yang sesuai dengan yang Anda terima di email Anda."
            // );
            setSub(false);
            setErrors("Kembali");
          }
        }
      })
      .catch((error) => console.log("error", error));

    // checkCode();
  }, [email, refreshToken, verify_code]);

  return (
    <>
      <div className="container mx-auto h-screen">
        <div className="flex content-center items-center justify-center h-screen">
          {/* <div className="w-full lg:w-1/12 px-1"> */}
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6">
            {go ? (
              <>
                <img
                  className="mx-auto h-28 w-28 content-center"
                  src={Email}
                  alt="emailImage"
                />
                <h1 className="text-center text-3xl font-bold mt-6">{title}</h1>
                <p className="text-center mt-4 mb-6">{getRespon}</p>
                <div className="text-center mt-6">
                  <Link
                    className="bg-blue text-white text-sm font-bold py-2 px-24 w-49-d text-center rounded shadow cursor:pointer"
                    to={"/"}
                  >
                    {respon}
                  </Link>
                </div>
              </>
            ) : (
              <>
                <img
                  className="mx-auto h-28 w-28 content-center"
                  src={image ? isKadaluarsa : isValid}
                  alt="emailImage"
                />
                <h1 className="text-center text-3xl font-bold mt-6">{title}</h1>
                {errorDiv}
                <div className="text-center mt-6">
                  {errors === "Kirim Ulang" && (
                    <a
                      className="bg-blue text-white text-sm font-bold py-2 px-24 w-49-d text-center rounded shadow cursor:pointer"
                      href="/login"
                      onClick={resendEmailRegist}
                    >
                      Kirim Ulang
                    </a>
                  )}
                  {errors === "Kembali" && (
                    <Link
                      className="bg-blue text-white text-sm font-bold py-2 px-24 w-49-d text-center rounded shadow cursor:pointer"
                      to={"/"}
                    >
                      Kembali
                    </Link>
                  )}
                </div>
              </>
            )}

            {/* {getRespon && (
                <>
                  <h1 className="text-center text-3xl font-bold mt-6">
                    {go
                      ? "Verifikasi Berhasil"
                      : "Email berhasil diverifikasi!"}
                  </h1>
                  <p className="text-center font-bold mt-2 mb-4">
                    {go
                      ? "Selamat, email Anda sudah berhasil terverifikasi"
                      : "Selamat, email Anda sudah berhasil terverifikasi"}
                  </p>
                  <div className="text-center mt-6">
                    <Link
                      className="bg-blue text-white text-sm font-bold py-2 px-24 w-49-d text-center rounded shadow cursor:pointer"
                      to={"/"}
                    >
                      Masuk
                    </Link>
                  </div>
                </>
              )} */}

            {/* {errors === "kode verifikasi kadaluarsa" && (
                <>
                  <h1 className="text-center text-3xl font-bold mt-6">
                    Tautan Verifikasi Email kedaluwarsa
                  </h1>
                  <p className="text-center font-bold mt-2 mb-4">
                    Sepertinya tautan verifikasi telah kedaluwarsa. jangan
                    khawatir, kami dapat mengirimkan tautannya lagi.
                  </p>
                  <div className="text-center mt-6">
                    <Link
                      className="bg-blue text-white text-sm font-bold py-2 px-24 w-49-d text-center rounded shadow cursor:pointer"
                      to={"/"}
                    >
                      Kirim Ulang
                    </Link>
                  </div>
                </>
              )} */}

            {/* error when status 400, respon when status 200 */}
            {/* {errors || respon} */}
            {/* {go}
              {getRespon}
              {errors} */}
          </div>
          {/* </div> */}
        </div>
      </div>
    </>
  );
}
