import React, { useEffect, useState } from "react";
import {
  AgoraVideoPlayer,
  createClient,
  createMicrophoneAndCameraTracks,
} from "agora-rtc-react";

// const token = dataDetailAjb.vr_room?.token;
//   "006bd72f14651f942cf9867b26ff6ac19caIACP9G/rEyBC9yL/PGKVwfNIezRQCx1b8A8Tjn8IUAfhBvUsvfnodm2HEAAS3k0BiuqbZAEAAQBim5pk";

const AgoraVideoCall = ({ dataDetailAjb, inviteTtd, id, token }) => {
  const setInCall = true;
  const appId = "bc00a29a790643d3876690610c267ec4"; //ENTER APP ID HERE
  // const appId = "15ade710d3de457bbd2ddc96f487c621"; //ENTER APP ID HERE
  
  let channelName = id;
  //   "006bd72f14651f942cf9867b26ff6ac19caIAD65H9CWXQGKv6CEzWDz4I/B4iY9jVif1Kij6FknhV7+8Ft0Xrodm2HEACGVtgBDvebZAEAAQCes5pk";
  //   "006bd72f14651f942cf9867b26ff6ac19caIAD/YOPOwozDx7VamcNamOYlaQhfOtHUMlt7U9tllipvhM2qkmICLYKIEAAiUHgASOKbZAEAAQAgk5pk";
  return (
    <div className="px-4 h-screen">
      <VideoCall
        setInCall={setInCall}
        dataDetailAjb={dataDetailAjb}
        inviteTtd={inviteTtd}
        id={id}
        channelName={channelName}
        token="006bc00a29a790643d3876690610c267ec4IACjwT7Br7cYWmNcxvdG1WhpH+N2lDBCByucg63uer1AKAZB1pAAAAAAEACT0dcCtiimZAEAAQBG5aRk"
        appId={appId}
      />
    </div>
  );
};

var val = localStorage.getItem("dataPPAT");
var object = JSON.parse(val);

const useClient = createClient({ mode: "rtc", codec: "vp8" });
const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks();

const VideoCall = (props) => {
  const { setInCall, dataDetailAjb, inviteTtd, id, channelName, token, appId } =
    props;

  const [users, setUsers] = useState([]);
  const [start, setStart] = useState(false);
  const client = useClient();
  const { ready, tracks } = useMicrophoneAndCameraTracks();

  const val = localStorage.getItem("dataPPAT");
  const object = JSON.parse(val);

  useEffect(() => {
    // function to initialise the SDK
    let init = async (name) => {
      client.on("user-published", async (user, mediaType) => {
        await client.subscribe(user, mediaType);
        if (mediaType === "video") {
          setUsers((prevUsers) => {
            return [...prevUsers, user];
          });
        }
        if (mediaType === "audio") {
          user.audioTrack?.play();
        }
      });
      client.on("user-unpublished", (user, type) => {
        console.log("unpublished", user, type);
        if (type === "audio") {
          user.audioTrack?.stop();
        }
        if (type === "video") {
          setUsers((prevUsers) => {
            return prevUsers.filter((User) => User.uid !== user.uid);
          });
        }
      });
      client.on("user-left", (user) => {
        setUsers((prevUsers) => {
          return prevUsers.filter((User) => User.uid !== user.uid);
        });
      });

      // await client.join(appId, name, token, null);
      if (tracks) {
        console.log("appid ", appId);
        console.log("token ", token);
        await client.join(
          appId,
          name,
          token || null,
          object.user_detail.name.toString()
        );
        await client.publish([tracks[0], tracks[1]]);
      }
      setStart(true);
    };
    if (ready && tracks) {
      console.log("init ready");
      init(channelName);
    }
  }, [channelName, client, ready, tracks,appId, object.user_detail.name, token]);

  return (
    <div className="App">
      <div className="grid grid-cols-2 p-3">
        {start && tracks && (
          <Videos
            users={users}
            tracks={tracks}
            ready={ready}
            setStart={setStart}
            setInCall={setInCall}
            dataDetailAjb={dataDetailAjb}
            inviteTtd={inviteTtd}
            id={id}
          />
        )}
      </div>
    </div>
  );
};

const Videos = (props) => {
  const { users, tracks, ready, setInCall, setStart } = props;

  return (
    <div>
      <div>
        {/* <div id="videos" className="grid grid-cols-2"> */}
        <div
          id="videos"
          className={users.length >= 1 ? "grid grid-cols-2" : ""}
        >
          <AgoraVideoPlayer className="vid" videoTrack={tracks[1]}>
            {/* <button className="bg-blue rounded-md text-white py-1 px-2 video-label">
              Tanda Tangan
            </button> */}
            <button className="bg-black text-white opacity-5 py-1 px-2 video-label">
              {object.ppat_detail.name}
            </button>
          </AgoraVideoPlayer>
          {users.length > 0 &&
            users.map((user, index) => {
              if (user.videoTrack) {
                return (
                  <>
                    <AgoraVideoPlayer
                      className="vid"
                      videoTrack={user.videoTrack}
                      key={user.uid}
                    >
                      <button className="bg-black text-white opacity-5 py-1 px-2 video-label">
                        {users[index].uid}
                      </button>
                      {/* <button
                        onClick={inviteTtd(
                          id,
                          dataDetailAjb.actors[index].user_email
                        )}
                        className="bg-blue rounded-md text-white mb-1 py-1 px-2"
                      >
                        Tanda Tangan
                      </button> */}
                    </AgoraVideoPlayer>
                  </>
                );
              } else return null;
            })}
        </div>
      </div>
      {ready && tracks && (
        <Controls tracks={tracks} setStart={setStart} setInCall={setInCall} />
      )}
    </div>
  );
};

export const Controls = (props) => {
  // const client = useClient();
  const { tracks } = props;
  // const { tracks, setStart, setInCall } = props;
  const [trackState, setTrackState] = useState({ video: true, audio: true });
  const mute = async (type) => {
    if (type === "audio") {
      await tracks[0].setEnabled(!trackState.audio);
      setTrackState((ps) => {
        return { ...ps, audio: !ps.audio };
      });
    } else if (type === "video") {
      await tracks[1].setEnabled(!trackState.video);
      setTrackState((ps) => {
        return { ...ps, video: !ps.video };
      });
    }
  };

  // const leaveChannel = async () => {
  //   await client.leave();
  //   client.removeAllListeners();
  //   tracks[0].close();
  //   tracks[1].close();
  //   setStart(false);
  //   setInCall(false);
  //   window.location.reload();
  // };

  return (
    <div className="controls w-full bg-white rounded-lg mt-2">
      <p onClick={() => mute("audio")} className="my-2">
        {trackState.audio ? (
          <i
            className={"fa fa-microphone fa-lg"}
            style={{ color: "black", width: "16.62px" }}
          ></i>
        ) : (
          <i
            className={"fa fa-microphone-slash fa-lg"}
            style={{ color: "black" }}
          ></i>
        )}
      </p>
      <p onClick={() => mute("video")}>
        {trackState.video ? (
          <i className={"fa fa-video fa-lg"} style={{ color: "black" }}></i>
        ) : (
          <i
            className={"fa fa-video-slash fa-lg"}
            style={{ color: "black" }}
          ></i>
        )}
      </p>
    </div>
  );
};

export default AgoraVideoCall;
