import React from "react";

export default function DocFilter({ search, setSearch }) {
  const hanldeSearch = (e) => {
    let value = e.target.value;
    let name = e.target.name;

    setSearch({ ...search, [name]: value });
  };

  const listAkta = [
    {
      judul: "Pendaftaran Peralihan Hak Jual",
    },
    {
      judul: "Surat Kuasa",
    },
    {
      judul: "Akta Jual Beli",
    },
    {
      judul: "Akta Pemberian Hak Tanggungan",
    },
    {
      judul: "Akta Tukar Menukar",
    },
    {
      judul: "Akta Hibah",
    },
    {
      judul: "Akta Masuk ke Perusahaan",
    },
    {
      judul: "Akta Pembagian Hak Bersama",
    },
    {
      judul: "Akta Pemberian Hak Guna Bangunan",
    },
    {
      judul: "Surat Kuasa Membebankan Hak Tanggungan",
    },
  ];

  const resetFilter = () => {
    setSearch({ type: "", nama_doc: "" });
  };
  return (
    <div className="my-3 float-right w-full text-right doc-filter">
      <input
        type="text"
        name="nama_doc"
        placeholder="Cari dokumen"
        className="rounded-lg text-xs mr-3"
        onChange={hanldeSearch}
        value={search.nama_doc}
      />
      <select
        className="mr-3 rounded-lg text-xs"
        name="type"
        onChange={hanldeSearch}
        value={search.type || null}
      >
        <option disabled selected>
          Kategori Dokumen
        </option>
        {listAkta.map((el) => (
          <option value={el.judul}>{el.judul}</option>
        ))}
      </select>
      {(search.type || search.nama_doc) && (
        <button className="focus:outline-none" onClick={resetFilter}>
          Hapus
        </button>
      )}
    </div>
  );
}
