import React, {useState} from "react";
//import { UserContext } from "../../../Context/UserContext";
import { AjbProvider } from "../../../Context/AjbContext";

import MultiStepProgressBar from "./MultiStepProgressBar";

import Form1 from "./Form1"
import Upload2 from "./Upload2"
import Emeterai3 from "./Emeterai3"
import TandaTgn4 from "./TandaTgn4"
import Review5 from "./Review5"


export default function ContainerPembubuh() {
    const [page, setPage] = useState("step1");
    const nextPage = (page) => {
        setPage(page);
      };

    return(
        <AjbProvider>
            <div>
                <div className="w-full p-4 px-4">
                    <MultiStepProgressBar currentStep={page} goNext={nextPage} />
                </div>
                <div className="mt-4">
                {
                    {
                        step1: <Form1 goNext={nextPage} />,
                        step2: <Upload2 goNext={nextPage} />,
                        step3: <Emeterai3 goNext={nextPage} />,
                        step4: <TandaTgn4 goNext={nextPage} />,
                        step5: <Review5 />,
                    }[page]
                }
                </div>
            </div>
        </AjbProvider>
    )
}