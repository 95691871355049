import React, {useState} from "react";
import OtpInput from "react-otp-input";

export default function ComponentModalOtp({setThisShow, thisSignid}) {
    const [kodeOtp, setKodeOtp] = useState("");
  const [getMinute, setGetMinute] = useState(0);
  const [getSecond, setGetSecond] = useState(0);
  const handleChange = (event) => {
    setKodeOtp(event);
  };
  const handleSubmit = () =>{
    setThisShow(false, kodeOtp, thisSignid);
  }
    return (<>
    <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-55 outline-none"
            //   onClick={() => setOtpModal(false)}
          >
            <div className="relative w-auto my-2 mx-auto bg-gray rounded-lg">
              {/*content*/}
              {/* <form> */}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white p-2 ">
                {/*body*/}
                <div className="relative p-6 px-12 flex-col text-blue font-roboto">
                  <label className="block text-center text-blue text-xl font-bold mb-4">
                    OTP Pembubuhan Tanda Tangan 
                  </label>
                  <label className="text-md text-black block text-center py-10">
                  Input OTP yang diberikan
                  </label>
                  <OtpInput
                    inputStyle="otpInput"
                    value={kodeOtp}
                    onChange={handleChange}
                    numInputs={6}
                    isInputNum={true}
                    name="otp"
                  />
                </div>
                <p className="text-blueGray-500 text-xs text-center mb-8">
                  Mohon menunggu {getMinute < 10 ? "0" + getMinute : getMinute}{" "}
                  : {getSecond < 10 ? "0" + getSecond : getSecond} untuk
                  melakukan
                  <br /> generate ulang kode OTP yang baru.
                </p>
              </div>
              <button
                onClick={handleSubmit}
                className="text-white float-right cursor-pointer text-xs bg-blue mt-2 border-blue rounded-lg background-transparent font-bold px-4 py-2 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              ><i className="fa fa-check mr-2"></i>
                Kirim OTP
              </button>
              {/* </form> */}
            </div>
          </div>
          <div  className="opacity-25 fixed inset-0 z-45 bg-black"></div>
    </>)
}