import React, { useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";
//import { UserContext } from "../../Context/UserContext";

export default function CertStatus() {
    //const UserProvider = userContext;
    // const { functions } = useContext(UserContext);
    // const { checkCert } = functions;
    const [show, setShow] = useState(false);
    const [verID, setVerID] = useState(false);
    const [verEkyc, setVerEkyc] = useState(false);
    const [verCA, setVerCA] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    

    const handleShow = () => {
        if(!show) setShow(true); else setShow(false) ;
    }
    var auth = localStorage.getItem("authentication");
    var token = JSON.parse(auth);
    var dataPPATjs = localStorage.getItem("dataPPAT");
    var dataPPATob = JSON.parse(dataPPATjs);
    useEffect(() => {
      //checkCert();
      if (dataPPATob.user_files.bpjs && dataPPATob.user_files.ktp &&
        dataPPATob.user_files.npwp) {
          setVerID(true);
        }
        if (dataPPATob.user_files.self_video && dataPPATob.user_files.selfie_photo) {
            setVerEkyc(true);
          }
        if (!dataPPATob.user_files.ca_status) {
           // if not exist in local then cek from api, so request only run once or always run if ca_status false
            setIsLoading(true);
            fetch(process.env.REACT_APP_BACKEND_HOST_AUTH + "api/users/check-ca", {
              method: "GET",
              headers: {
                Authorization: "Bearer " + token.access_token,
              },
            })
            .then((response) => {
              setIsLoading(false);
              // alert(response)
              if (response.status === 401) {
                //refreshToken();
              } else {
                return response.json();
              }
            })
            .then((res) => {
                if (res.success === true) {
                  //console.log("fetch CA True");
                  if (res.data.ca_registered === true) {
                    dataPPATob.user_files.ca_status = true;
                  localStorage.setItem("dataPPAT", JSON.stringify(dataPPATob));
                  setVerCA(true);
                  } else {
                    dataPPATob.user_files.ca_status = false;
                    localStorage.setItem("dataPPAT", JSON.stringify(dataPPATob));
                  }
                  
                } else {
                  
                }
              })
          } else {
            setVerCA(dataPPATob.user_files.ca_status);
          }
         
      
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    return(
        <>
         <div className="px-2 py-1">
            {isLoading && (
              <div className="text-xs">Cek CA ...</div>
            )}

              { !isLoading && verID && verEkyc && verCA ? (
                  <button onClick={handleShow}
                  className="w-full px-2 py-1 bg-gradient-to-r from-green-100 via-green-200 to-green-400 bg-green-200 text-grey text-center inline-flex items-center rounded text-sm">
                    <i className="mr-2 fas fa-check-circle"></i> Verifikasi Akun </button>
              ) : (
                <button onClick={handleShow}
                className="w-full px-2 py-1 bg-red-400 text-white text-center inline-flex items-center rounded text-sm">
                  <i className="mr-2 fas fa-times-circle"></i> Verifikasi Akun </button>
              ) }
                
                
                { show && (
                    <>
                        <div className="flex  text-sm pt-1  w-full">
                        <div className="ml-2 border-l w-6/12">
                          <span className="ml-2">Verifikasi ID</span>
                          <a className="rounded ml-1" data-tip data-for='verIDTip'>
                           <i className="mr-2 fas fa-info-circle"></i>
                          </a>
                          <ReactTooltip id='verIDTip' type="dark" effect="solid">
                            <span>Foto kartu identitas Anda seperti <br />KTP, NPWP dan BPJS untuk <br />verifikasi data Dukcapil</span>
                          </ReactTooltip>
                        </div>
                        <div className="flex justify-end w-6/12">
                          { verID ? (
                            <button className="px-2 py-1 bg-green-200 rounded text-xs">Sudah</button>
                          ) : (
                            <button className="px-2 py-1 bg-red-200 rounded text-xs">Belum</button>
                          )}
                          
                        </div>
                      </div>
                      <div className="flex  text-sm pt-1  w-full">
                        <div className="ml-2 border-l w-8/12">
                          <span className="ml-2">Verifikasi e-KYC</span>
                        <a className="rounded ml-1" data-tip data-for='verEkycTip'>
                           <i className="mr-2 fas fa-info-circle"></i>
                          </a>
                          <ReactTooltip id='verEkycTip' type="dark" effect="solid">
                            <span>Isi formulir data diri berdasarkan<br />
                                  Kartu Identitas termasuk swafoto<br />
                                  dan rekam wajah untuk e-KYC</span>
                          </ReactTooltip>
                        </div>
                        <div className="flex justify-end w-4/12">
                        { verEkyc ? (
                            <button className="px-2 py-1 bg-green-200 rounded text-xs">Sudah</button>
                          ) : (
                            <button className="px-2 py-1 bg-red-200 rounded text-xs">Belum</button>
                          )}
                        </div>
                      </div>
                      <div className="flex  text-sm pt-1  w-full">
                        <div className="ml-2 border-l w-6/12">
                          <span className="ml-2">Verifikasi CA</span>
                        <a className="rounded ml-1" data-tip data-for='verCaTip'>
                           <i className="mr-2 fas fa-info-circle"></i>
                          </a>
                          <ReactTooltip id='verCaTip' type="dark" effect="solid">
                            <span>Pembuatan tanda tangan digital atau <br />
tanda tangan elektronik tersertifikasi<br />
sesuai dengan data diri Anda</span>
                          </ReactTooltip>
                        </div>
                        <div className="flex justify-end w-6/12">
                        { verCA ? (
                            <button className="px-2 py-1 bg-green-200 rounded text-xs">Sudah</button>
                          ) : (
                            <button className="px-2 py-1 bg-red-200 rounded text-xs">Belum</button>
                          )}
                        </div>
                      </div>
                    </>
                )}
                  
                <hr className="mt-4 mx-auto sidebar-line" />
                <button
                    className="list-akta-btn mx-auto w-full focus:outline-none"
                    onClick={handleShow}
                  >
                    { show ? ( 
                      <i className="mr-2 fas fa-chevron-circle-up"></i>
                     )
                    : (
                      <i className="mr-2 fas fa-chevron-circle-down"></i>
                      )}
                  </button>
            
              </div>
    </>
    )
}