import React, { createContext, useState } from "react";
import cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
export const RegistContext = createContext();

export const RegistProvider = (props) => {
  const history = useHistory();
  // simpan field to context
  const [regist, setRegist] = useState({
    email: "",
    password: "",
    repassword: "",
  });
  const [inputRegist, setInputRegist] = useState([]);
  const [dataRegist, setDataRegist] = useState([""]);
  const [tipe, setTipe] = useState([""]);
  const [skppat, setSkppat] = useState([""]);
  //url on env
  const apiRegist =
    process.env.REACT_APP_BACKEND_HOST_AUTH + "api/auth/register";
  //get dropdown kota & provinsi
  const [dataProv, setDataProv] = useState([]);
  const [dataKota, setDataKota] = useState([]);
  const [dataKec, setDataKec] = useState([]);
  const [dataKel, setDataKel] = useState([]);
  const [dataCityFilter1, setdataCityFilter1] = useState([]);
  const [dataDistrictFilter1, setdataDistrictFilter1] = useState([]);
  const [dataLurahFilter1, setdataLurahFilter1] = useState([]);
  const [dataCityFilter, setdataCityFilter] = useState([]);
  const [dataDistrictFilter, setdataDistrictFilter] = useState([]);
  const [dataLurahFilter, setdataLurahFilter] = useState([]);
  const [ttdImage, setTtdImage] = useState("");
  const [fileLengkapiDiri, setFileLengkapiDiri] = useState("");
  const [loading, setLoading] = useState(false);
  const [dataUser, setDataUser] = useState([]);

  var dataPPATjson = localStorage.getItem("dataPPAT");
  var dataPPAT = JSON.parse(dataPPATjson);

  var auth = localStorage.getItem("authentication");
  var token = JSON.parse(auth);

  var userData = localStorage.getItem("user-info");
  var user = JSON.parse(userData);

  var dataDiri = JSON.parse(localStorage.getItem("dataDiri"));

  const refreshToken = () => {
    fetch(process.env.REACT_APP_BACKEND_HOST_AUTH + "api/auth/refresh-token", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        refresh_token: token.refresh_token,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result.success === true) {
          token.access_token = result.data.access_token;
          localStorage.setItem("authentication", JSON.stringify(token));
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          swal("Gagal", "Silahkan login kembali", "error");
          localStorage.removeItem("dataPPAT");
          localStorage.removeItem("authentication");
          setTimeout(() => {
            history.push("/login");
          }, 1000);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const b64toBlob = (b64Data, contentType, sliceSize) => {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;
    var byteCharacters = atob(b64Data);
    var byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const ppatFile = (type, dataFile) => {
    setLoading(true);
    let myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token.access_token);

    let formdata = new FormData();
    formdata.append("file_type", type);
    formdata.append("file", dataFile);

    let requestOptionsGet = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: formdata,
    };

    fetch(
      process.env.REACT_APP_BACKEND_HOST_AUTH + "api/update-profile/file",
      requestOptionsGet
    )
      .then((res) => {
        if (res.status === 401) {
          refreshToken();
        } else {
          return res.json();
        }
      })
      .then((response) => {
        if (response.success) {
          //console.log(dataPPAT);
          if(type === "sk_pengangkatan")
            dataPPAT.user_files.sk_pengangkatan = true;
          if(type === "ktp")
            dataPPAT.user_files.ktp = true;
          if(type === "npwp")
            dataPPAT.user_files.npwp = true;
          if(type === "bpjs")
            dataPPAT.user_files.bpjs = true;
          if(type === "selfie_photo")
            dataPPAT.user_files.selfie_photo = true;
          
          localStorage.setItem("dataPPAT", JSON.stringify(dataPPAT));
          //console.log(dataPPAT);
          swal("Berhasil", response.data.message, "success");
          setLoading(false);
        } else {
          setLoading(false);
          swal("Gagal", response.error, "error");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
      });
  };

  const ppatDetail = () => {
    var dates = dataDiri.tgl_sk;

    function padTo2Digits(num) {
      return num.toString().padStart(2, "0");
    }

    function formatDate(date) {
      return [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
      ].join("/");
    }

    let tanggalan = formatDate(new Date(dates));

    fetch(
      process.env.REACT_APP_BACKEND_HOST_AUTH +
        "api/update-profile/ppat-details",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token.access_token,
        },
        body: JSON.stringify({
          no_sk: dataDiri.no_sk_pengangkatan,
          sk_date: tanggalan,
          ppat_name: dataDiri.ppat_name,
          ppat_address:
            dataDiri.ppat_alamat +
            ", Rukun Tetangga " +
            dataDiri.ppat_rt +
            ", Rukun Warga " +
            dataDiri.ppat_rw +
            ", Kode Pos " +
            dataDiri.ppat_kodepos,
          ppat_district_id: dataDiri.ppat_kecamatan,
        }),
      }
    )
      .then((res) => {
        if (res.status === 401) {
          refreshToken();
        } else {
          return res.json();
        }
      })
      .then((response) => {
        if (response.success) {
          swal("Berhasil", response.data.message, "success");
          setLoading(false);
        } else {
          setLoading(false);
          swal("Gagal", response.error, "error");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const verifVideo = (type, dataFile) => {
    let myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token.access_token);

    let formdata = new FormData();
    formdata.append("file_type", type);
    formdata.append("file", dataFile);

    let requestOptionsGet = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: formdata,
    };
    fetch(
      process.env.REACT_APP_BACKEND_HOST_AUTH +
        "api/update-profile/video-verification",
      requestOptionsGet
    )
      .then((response) => {
        if (response.status === 401) {
          refreshToken();
        } else {
          return response.json();
        }
      })
      .then((res) => {
        setLoading(false);
        console.log(res.success);
        if (res.success) {
          dataPPAT.user_files.self_video = true;
          localStorage.setItem("dataPPAT", JSON.stringify(dataPPAT));
          swal("Berhasil", res.data.message + res.data.kyc_description, "success");
          history.push("/lengkapiDiri/sign");
        } else {
          swal("Gagal", res.error, "error");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getUserFile = (type) => {
    fetch(
      process.env.REACT_APP_BACKEND_HOST_AUTH +
        "api/users/get-file?file_type=" +
        type,
      {
        method: "GET",
        redirect: "follow",
        headers: {
          Authorization: "Bearer " + token.access_token,
        },
      }
    )
      .then((response) => {
        if (response.status === 401) {
          refreshToken();
        } else {
          return response.blob();
        }
      })
      .then((result) => {
        setFileLengkapiDiri(result);
        // if (type === "selfie_photo") {
        //   // localStorage.setItem(type, )
        //   setInputRegist({ ...inputRegist, type: result });
        //   getUserFile("self_video");
        // } else {
        //   setInputRegist({ ...inputRegist, type: result });
        //   setTimeout(() => {
        //     sendLengkapiDiriUmum();
        //   }, 2000);
        // }
        // setTtdImage(URL.createObjectURL(result));
      })
      .catch((error) => console.log("error", error));
  };

  const getDataProv = () => {
    fetch(process.env.REACT_APP_BACKEND_HOST_AUTH + "api/loc/provinces", {
      method: "GET",
    })
      .then((res) => {
        if (res.status === 401) {
          refreshToken();
        } else {
          return res.json();
        }
      })
      .then((response) => setDataProv(response.data))
      .catch((error) => console.log("error", error));
  };

  const getDataKota = () => {
    fetch(process.env.REACT_APP_BACKEND_HOST_AUTH + "api/loc/cities", {
      method: "GET",
    })
      .then((res) => {
        if (res.status === 401) {
          refreshToken();
        } else {
          return res.json();
        }
      })
      .then((response) => setDataKota(response.data))
      .catch((error) => console.log("error", error));
  };

  const getDataKec = () => {
    fetch(process.env.REACT_APP_BACKEND_HOST_AUTH + "api/loc/districts", {
      method: "GET",
    })
      .then((res) => {
        if (res.status === 401) {
          refreshToken();
        } else {
          return res.json();
        }
      })
      .then((response) => {
        setDataKec(response.data);
      })
      .catch((error) => console.log("error", error));
  };

  const getDataKel = (district_id) => {
    fetch(
      process.env.REACT_APP_BACKEND_HOST_AUTH +
        "api/loc/kelurahan?district_id=" +
        district_id,
      {
        method: "GET",
      }
    )
      .then((res) => {
        if (res.status === 401) {
          refreshToken();
        } else {
          return res.json();
        }
      })
      .then((response) => {
        setDataKel(response.data);
      })
      .catch((error) => console.log("error", error));
  };

  const getCityFilter1 = (id_prov) => {
    let id = Number(id_prov);
    const filterData = dataKota.filter((e) => {
      return e.id_provinsi === id;
    });
    setdataCityFilter1(filterData);
  };

  const getDistrictFilter1 = (id_kota) => {
    let id = Number(id_kota);
    const filterData = dataKec.filter((e) => {
      return e.id_kota === id;
    });
    setdataDistrictFilter1(filterData);
  };

  const getLurahFilter1 = (id_camat) => {
    let id = Number(id_camat);
    const filterData = dataKel.filter((e) => {
      return e.id_camat === id;
    });
    setdataLurahFilter1(filterData);
  };

  const getCityFilter = (ppat_prov) => {
    let id = Number(ppat_prov);
    const filterData = dataKota.filter((e) => {
      return e.province_id === id;
    });
    setdataCityFilter(filterData);
  };

  const getDistrictFilter = (ppat_kotkab) => {
    let id = Number(ppat_kotkab);
    const filterData = dataKec.filter((e) => {
      return e.city_id === id;
    });
    setdataDistrictFilter(filterData);
  };

  const getLurahFilter = (ppat_kecamatan) => {
    let id = Number(ppat_kecamatan);
    const filterData = dataKel.filter((e) => {
      return e.ppat_kecamatan === id;
    });
    setdataLurahFilter(filterData);
  };

  const all = {
    getDataProv,
    getDataKota,
    getDataKec,
    getDataKel,

    getCityFilter1,
    getDistrictFilter1,
    getLurahFilter1,

    getCityFilter,
    getDistrictFilter,
    getLurahFilter,
  };

  const resendEmailRegist = async (event) => {
    event.preventDefault();
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "REVEL_FLASH=");
    // myHeaders.append("Authorization", "Bearer " + token.access_token);

    let raw = JSON.stringify({
      user_id: user.user_id,
    });

    let requestOptionsGet = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_BACKEND_HOST_AUTH +
        "api/auth/register/resend-email",
      // process.env.REACT_APP_BACKEND_HOST_AUTH + "api/verifikasi/email/send",
      requestOptionsGet
    )
      // .then((res) => res.json())
      // .then((res) => {
      //   if (res.status === 401) {
      //     refreshToken();
      //   } else {
      //     return res.json();
      //   }
      // })
      // .then(() => {
      //   cookies.set("tipe_otp", "registrasi", { expires: 1 });
      //   history.push("/modal2");
      // })
      .then((res) => res.json())
      .then(() => {
        cookies.set("tipe_otp", "registrasi", { expires: 1 });
        swal({
          title: "Mengirim Ulang E-mail Aktivasi!",
          text: "Silahkan periksa e-mail Anda",
          icon: "info",
        });
      })
      .catch((error) => console.log("error", error));
  };

  // Post API LengkapiDiri
  const sendLengkapiDiriUmum = () => {
    if (dataPPATjson) {
      var dates = dataDiri.tanggal_lahir || "mm/dd/yyyy";
    }

    if (
      !dataPPAT.user_files.ktp || 
      !dataPPAT.user_files.npwp || 
      !dataPPAT.user_files.bpjs || 
      !dataPPAT.user_files.selfie_photo  

      ) {
        console.log("Failed user files")
      let nama_file_upload_yg_belum = "";
      
      if (!dataPPAT.user_files.ktp)
        nama_file_upload_yg_belum += "\nFoto KTP ";
      if (!dataPPAT.user_files.npwp)
        nama_file_upload_yg_belum += "\nFoto NPWP ";
      if (!dataPPAT.user_files.bpjs)
        nama_file_upload_yg_belum += "\nFoto BPJS ";
      if (!dataPPAT.user_files.selfie_photo)
        nama_file_upload_yg_belum += "\nSelfie Foto ";
      swal({
        title: "Gagal!",
        text: "Upload File belum semua, cek kembali " + nama_file_upload_yg_belum,
        icon: "warning",
      });
      return false;
    }
    // var dates = dataDiri?.tanggal_lahir;

    function padTo2Digits(num) {
      return num.toString().padStart(2, "0");
    }

    function formatDate(date) {
      return [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
      ].join("/");
    }

    let tanggalan = formatDate(new Date(dates));

    fetch(process.env.REACT_APP_BACKEND_HOST_AUTH + "api/update-profile/kyc", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token.access_token,
      },
      body: JSON.stringify({
        name: dataDiri.nama,
        place_of_birth: dataDiri.tempat_lahir,
        date_of_birth: tanggalan,
        gender: dataDiri.gender,
        marriage_status: dataDiri.status_nikah,
        address:
          dataDiri.alamat +
          ", Rukun Tetangga " +
          dataDiri.rt +
          ", Rukun Warga " +
          dataDiri.rw +
          ", Kelurahan " +
          dataDiri.id_lurah,
        province_id: dataDiri.id_prov,
        city_id: dataDiri.id_kota,
        district_id: dataDiri.id_camat,
        post_code: dataDiri.kodepos,
        no_nik: dataDiri.no_nik,
        no_npwp: dataDiri.no_npwp,
        role: "umum",
        // selfie_photo: inputRegist.selfie_photo,
        // self_video: inputRegist.self_video,
      }),
    })
      .then((res) => {
        if (res.status === 401) {
          refreshToken();
        } else {
          return res.json();
        }
      })
      .then((res) => {
        setLoading(false);
        let sukses = res.success;

        if (!sukses) {
          if (res.error === "Customer Already Registered") {
            swal({
              title: "Gagal!",
              text: "NIK Sudah Terdaftar",
              icon: "warning",
            });
          } else {
            swal({
              title: "Gagal!",
              text: res.error,
              icon: "warning",
            });
          }
        } else {
          swal({
            title: "Berhasil",
            text: res.data.message,
            icon: "success",
          });
          cookies.set("step", "6");
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      });
  };

  const sendLengkapiDiriPPAT = () => {
    // const arr = [];
    // if (!/^[0-9\b]+$/.test(dataDiri.no_nik)) {
    //   arr.push("No.handphone tidak valid");
    // }

    if (dataPPAT) {
      var dates = dataDiri?.tanggal_lahir || "mm/dd/yyyy";
      if (!dataPPAT.user_files.sk_pengangkatan || 
        !dataPPAT.user_files.ktp || 
        !dataPPAT.user_files.npwp || 
        !dataPPAT.user_files.bpjs || 
        !dataPPAT.user_files.selfie_photo  

        ) {
          console.log("Failed user files")
        let nama_file_upload_yg_belum = "";
        if (!dataPPAT.user_files.sk_pengangkatan)
          nama_file_upload_yg_belum += "\nSK Pengangkatan ";
        if (!dataPPAT.user_files.ktp)
          nama_file_upload_yg_belum += "\nFoto KTP ";
        if (!dataPPAT.user_files.npwp)
          nama_file_upload_yg_belum += "\nFoto NPWP ";
        if (!dataPPAT.user_files.bpjs)
          nama_file_upload_yg_belum += "\nFoto BPJS ";
        if (!dataPPAT.user_files.selfie_photo)
          nama_file_upload_yg_belum += "\nSelfie Foto ";
        swal({
          title: "Gagal!",
          text: "Upload File belum semua, cek kembali " + nama_file_upload_yg_belum,
          icon: "warning",
        });
        return false;
      }
      
    }

    function padTo2Digits(num) {
      return num.toString().padStart(2, "0");
    }

    function formatDate(date) {
      return [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
      ].join("/");
    }

    let tanggalan = formatDate(new Date(dates));

    fetch(process.env.REACT_APP_BACKEND_HOST_AUTH + "api/update-profile/kyc", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token.access_token,
      },
      body: JSON.stringify({
        name: dataDiri.nama,
        place_of_birth: dataDiri.tempat_lahir,
        date_of_birth: tanggalan,
        gender: dataDiri.gender,
        marriage_status: dataDiri.status_nikah,
        address:
          dataDiri.alamat +
          ", Rukun Tetangga " +
          dataDiri.rt +
          ", Rukun Warga " +
          dataDiri.rw +
          ", Kelurahan " +
          dataDiri.id_lurah,
        province_id: dataDiri.id_prov,
        city_id: dataDiri.id_kota,
        district_id: dataDiri.id_camat,
        post_code: dataDiri.kodepos,
        no_nik: dataDiri.no_nik,
        no_npwp: dataDiri.no_npwp,
        role: "ppat",
        // selfie_photo: inputRegist.selfie_photo,
        // self_video: inputRegist.self_video,
      }),
    })
      .then((res) => {
        if (res.status === 401) {
          refreshToken();
        } else {
          return res.json();
        }
      })
      .then((res) => {
        setLoading(false);
        let sukses = res.success;

        if (!sukses) {
          if (res.error === "Customer Already Registered") {
            swal({
              title: "Gagal!",
              text: "NIK Sudah Terdaftar",
              icon: "warning",
            });
          } else {
            swal({
              title: "Gagal!",
              text: res.error,
              icon: "warning",
            });
          }
        } else {
          swal({
            title: "Berhasil",
            text: res.data.message,
            icon: "success",
          });
          cookies.set("step", "7");
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      });
    ppatDetail();
  };

  const sendMailLengkapiDiri = async () => {
    history.push("/modal3");
  };

  const resendOTPRegist = async () => {
    cookies.remove("token");
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "REVEL_FLASH=");
    myHeaders.append("Authorization", "Bearer " + token.access_token);

    let raw = JSON.stringify({
      user_id: dataPPAT.uid,
    });

    let requestOptionsGet = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    await fetch(
      process.env.REACT_APP_BACKEND_HOST_AUTH + "api/auth/register/resend-otp",
      requestOptionsGet
    )
      // .then((res) => res.json())
      .then((res) => {
        if (res.status === 401) {
          refreshToken();
        } else {
          return res.json();
        }
      })
      .then(() => {
        console.log(true);
      })
      .catch((error) => console.log("error", error));
  };

  const verifiedOTP = async (otp) => {
    cookies.remove("token");

    await fetch(
      process.env.REACT_APP_BACKEND_HOST_AUTH +
        "api/auth/register/verify-phone-number",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          user_id: cookies.get("uid"),
          otp_code: otp,
        }),
      }
    )
      // .then((res) => res.json())
      // .then((res) => {
      //   if (res.status === 401) {
      //     refreshToken();
      //   } else {
      //     return res.json();
      //   }
      // })
      // .then((res) => {
      //   let data = res.data;
      //   let sukses = res.success;

      //   if (!sukses) {
      //     swal({
      //       title: "Gagal!",
      //       text: res.error,
      //       icon: "warning",
      //     });
      //   }

      //   if (data === null) {
      //     if (res.error === "OTP not valid") {
      //       swal({
      //         title: "Gagal!",
      //         text: "OTP Tidak Valid",
      //         icon: "warning",
      //       });
      //     }
      //   } else {
      //     let isLengkap = res.data.lengkapidiri;
      //     // console.log(res.data);
      //     if (isLengkap === true) {
      //       history.push("/admin/dashboard");
      //     } else {
      //       history.push("/modalverif");
      //     }
      //   }
      // })
      .then((res) => res.json())
      .then((res) => {
        if (!res.success) {
          swal("Error", res.error, "error");
          setLoading(false);
        } else {
          let isLengkap = res.data.lengkapidiri;
          // console.log(res.data);
          if (isLengkap === true) {
            history.push("/admin/dashboard");
          } else {
            history.push("/modalverif");
          }
          swal({
            title: "Berhasil",
            text: res.data.message,
            icon: "success",
            buttons: false,
          });
        }
      })
      .catch((error) => console.log("error", error));
  };

  const cekKTPPPAT = async () => {
    // event.preventDefault();
    let myHeaders = new Headers();
    myHeaders.append("Cookie", "REVEL_FLASH=");
    myHeaders.append("Authorization", "Bearer " + token.access_token);
    // myHeaders.append("Content-Type", "multipart/form-data");
    let uid = dataPPAT.uid;
    let formdata = new FormData();
    formdata.append("uid", uid);
    formdata.append("nama", cookies.get("nama"));
    formdata.append("tempat_lahir", cookies.get("tempat_lahir"));
    formdata.append("tanggal_lahir", cookies.get("tanggal_lahir"));
    formdata.append("gender", cookies.get("gender"));
    formdata.append("status_nikah", cookies.get("status_nikah"));
    formdata.append("alamat", cookies.get("alamat"));
    formdata.append("prov", cookies.get("id_prov"));
    formdata.append("kotkab", cookies.get("id_kota"));
    formdata.append("kecamatan", cookies.get("id_camat"));
    formdata.append("kodepos", cookies.get("kodepos"));

    formdata.append("no_nik", cookies.get("no_nik"));
    formdata.append("no_npwp", cookies.get("no_npwp"));
    formdata.append("no_sk_pengangkatan", cookies.get("no_sk_pengangkatan"));
    formdata.append("tgl_sk", cookies.get("tgl_sk"));
    formdata.append("ppat_name", cookies.get("ppat_name"));
    formdata.append("ppat_alamat", cookies.get("ppat_alamat"));
    formdata.append("ppat_prov", cookies.get("ppat_prov"));
    formdata.append("ppat_kotkab", cookies.get("ppat_kotkab"));
    formdata.append("ppat_kecamatan", cookies.get("ppat_kecamatan"));
    formdata.append("bypass_ekyc", "true");
    formdata.append("roles", "ppat");

    let requestOptions = {
      method: "POST",
      credentials: "same-origin",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    // try {
    // setLoad(true);

    await fetch(
      process.env.REACT_APP_BACKEND_HOST_AUTH + "api/lengkapidiri/update",
      requestOptions
    )
      // .then((res) => res.json())
      .then((res) => {
        if (res.status === 401) {
          refreshToken();
        } else {
          return res.json();
        }
      })
      .then((res) => {
        let data = res.data;
        let sukses = res.success;

        if (data === null && sukses === false) {
          swal({
            title: "Gagal!",
            text: res.error,
            icon: "error",
          });
          console.log(uid);
        } else if (sukses === true) {
          console.log(res);
          console.log(uid);
        }
      })
      .catch((error) => {});
  };

  const cekKTP = async () => {
    // event.preventDefault();
    let myHeaders = new Headers();
    myHeaders.append("Cookie", "REVEL_FLASH=");
    myHeaders.append("Authorization", "Bearer " + token.access_token);
    // myHeaders.append("Content-Type", "multipart/form-data");
    let uid = dataPPAT.uid;
    let formdata = new FormData();
    formdata.append("uid", uid);
    formdata.append("nama", cookies.get("nama"));
    formdata.append("tempat_lahir", cookies.get("tempat_lahir"));
    formdata.append("tanggal_lahir", cookies.get("tanggal_lahir"));
    formdata.append("gender", cookies.get("gender"));
    formdata.append("status_nikah", cookies.get("status_nikah"));
    formdata.append("alamat", cookies.get("alamat"));
    formdata.append("prov", cookies.get("id_prov"));
    formdata.append("kotkab", cookies.get("id_kota"));
    formdata.append("kecamatan", cookies.get("id_camat"));
    formdata.append("kodepos", cookies.get("kodepos"));
    formdata.append("no_nik", cookies.get("no_nik"));
    formdata.append("no_npwp", cookies.get("no_npwp"));
    formdata.append("bypass_ekyc", "true");
    formdata.append("roles", "ppat");

    let requestOptions = {
      method: "POST",
      credentials: "same-origin",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    await fetch(
      process.env.REACT_APP_BACKEND_HOST_AUTH + "api/lengkapidiri/update",
      requestOptions
    )
      .then((res) => {
        if (res.status === 401) {
          refreshToken();
        } else {
          return res.json();
        }
      })
      .then((res) => {
        let data = res.data;
        let sukses = res.success;

        if (data === null && sukses === false) {
          swal({
            title: "Gagal!",
            text: res.error,
            icon: "error",
          });
          console.log(uid);
        } else if (sukses === true) {
          console.log(res);
          console.log(uid);
        }
      })
      .catch((error) => {});
  };

  const toSign = async () => {
    history.push("/lengkapiDiri/sign");
  };

  const toCA = async () => {
    history.push("/lengkapiDiri/ca");
  };

  const stepper = async () => {
    history.push("/stepper");
  };

  const getTTD = () => {
    fetch(
      process.env.REACT_APP_BACKEND_HOST_AUTH +
        "api/lengkapidiri/download/" +
        dataPPAT.uid +
        "/specimen_tdtgn_file",
      {
        method: "GET",
        // body: formdata,
        redirect: "follow",
      }
    )
      .then((response) => {
        if (response.status === 401) {
          refreshToken();
        } else {
          return response.blob();
        }
      })
      .then((result) => {
        setTtdImage(URL.createObjectURL(result));
      })
      .catch((err) => console.log(err));
  };

  const functions = { getTTD };

  const fetchDataUser = () => {
    fetch(process.env.REACT_APP_BACKEND_HOST_AUTH + "api/auth/match-token", {
      method: "GET",
      redirect: "follow",
      headers: { Authorization: "Bearer " + token.access_token },
    })
      .then((response) => {
        if (response.status === 401) {
          refreshToken();
        } else if (response.status === 500) {
          swal("Error", "Internal Server Error", "error");
        } else {
          return response.json();
        }
      })
      .then((result) => {
        setDataUser(result.data);
        localStorage.setItem("dataPPAT", JSON.stringify(result.data));
        setTimeout(() => {
          if (!window.location.pathname.includes("dashboard")) {
            history.push("/admin/dashboard");
            window.location.reload();
          }
        }, 3000);
      })
      .catch((error) => fetchDataUser());
  };

  return (
    <RegistContext.Provider
      value={{
        apiRegist,
        functions,
        regist,
        setRegist,
        tipe,
        setTipe,
        skppat,
        setSkppat,
        inputRegist,
        setInputRegist,
        dataRegist,
        setDataRegist,

        dataProv,
        setDataProv,
        dataKota,
        setDataKota,
        dataKec,
        setDataKec,
        dataKel,
        setDataKel,

        getDataProv,
        getDataKota,
        getDataKec,
        getDataKel,

        getUserFile,

        getCityFilter1,
        getDistrictFilter1,
        getLurahFilter1,

        getCityFilter,
        getDistrictFilter,
        getLurahFilter,

        dataCityFilter1,
        setdataCityFilter1,
        dataCityFilter,
        setdataCityFilter,

        dataDistrictFilter1,
        setdataDistrictFilter1,
        dataDistrictFilter,
        setdataDistrictFilter,

        dataLurahFilter1,
        setdataLurahFilter1,
        dataLurahFilter,
        setdataLurahFilter,

        all,
        resendEmailRegist,
        sendMailLengkapiDiri,
        sendLengkapiDiriUmum,
        sendLengkapiDiriPPAT,
        verifiedOTP,
        resendOTPRegist,
        // testFaceAPI,
        cekKTP,
        cekKTPPPAT,
        toSign,
        toCA,
        stepper,
        refreshToken,
        ttdImage,
        setTtdImage,
        ppatFile,
        ppatDetail,
        verifVideo,
        loading,
        setLoading,
        b64toBlob,
        fileLengkapiDiri,
        setFileLengkapiDiri,
        fetchDataUser,
        dataUser,
        setDataUser,
      }}
    >
      {props.children}
    </RegistContext.Provider>
  );
};
